import React from "react"
import PropTypes from "prop-types"

import { Title } from "@nerdwallet/react-typography"

import styles from "./Title.module.less"

const SectionTitle = ({ children, component = "h3", align = "left" }) => (
  <Title
    size="large"
    component={component}
    align={align}
    className={styles.title}
  >
    {children}
  </Title>
)

SectionTitle.propTypes = {
  children: PropTypes.string.isRequired,
  component: PropTypes.oneOf(["h3", "div"]),
  align: PropTypes.oneOf(["left", "center"]),
}

export default SectionTitle
