import React from "react"
import PropTypes from "prop-types"

import styles from "./List.module.less"

const List = ({ items }) => {
  return (
    <ul className={styles.list}>
      {items.map(item => (
        <li className={styles.item} key={item}>
          {item}
        </li>
      ))}
    </ul>
  )
}

List.propTypes = {
  items: PropTypes.arrayOf(PropTypes.string.isRequired).isRequired,
}

export default List
