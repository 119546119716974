import React from "react"
import PropTypes from "prop-types"

import { Text } from "@nerdwallet/currency"

import StructuredContentRenderer from "@src/components/StructuredContentRenderer"
import imageSrcVertical from "@src/assets/article-hero-vertical.jpg"
import imageSrcHorizontal from "@src/assets/article-hero-horizontal.jpg"
import trustPilotImage from "./trustpilot-badge.png"
import funderaLogo from "@src/svg/fundera-by-nw.svg"

import styles from "./Hero.module.less"

const SubTitle = () => (
  <>
    <Text component="h2" size="large">
      Get the right financing for your business needs.
    </Text>
    <br />
    <img
      className={styles.funderaLogo}
      src={funderaLogo}
      alt="Fundera by NerdWallet"
      width={200}
      height={52}
    />
    <img
      className={styles.trustpilotLogo}
      src={trustPilotImage}
      alt="TrustPilot"
      width={130}
      height={49}
    />
  </>
)

const Hero = ({ title }) => {
  const content = [
    {
      type: "ArticleHero",
      version: "1.0.0",
      props: {
        title,
        subTitle: <SubTitle />,
        ctaComponent: "FunderaApplyForm",
        ctaComponentProps: {},
        backgroundColor: "yellow-lightest",
        hideImageOnMobile: true,
        wideImageData: {
          src: imageSrcVertical,
          alt: "",
        },
        imageData: {
          src: imageSrcHorizontal,
          alt: "",
        },
      },
    },
  ]

  return <StructuredContentRenderer>{content}</StructuredContentRenderer>
}

Hero.propTypes = {
  title: PropTypes.string.isRequired,
}

export default Hero
