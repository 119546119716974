import React from "react"

import Columns from "@src/components/Columns/Columns"

import styles from "./SocialProof.module.less"

import PersonalLoans from "@nerdwallet/react-icon/nerdwallet-brand/PersonalLoans"
import NetWorth from "@nerdwallet/react-icon/nerdwallet-brand/NetWorth"
import Savings from "@nerdwallet/react-icon/nerdwallet-brand/Savings"
import Banking from "@nerdwallet/react-icon/nerdwallet-brand/Banking"
import ColumnCard from "./ColumnCard"

const SocialProof = () => {
  return (
    <Columns gap>
      <ColumnCard icon={PersonalLoans}>
        <span className={styles.emphasis}>$2.5 billion+</span>
        <br />
        funded
      </ColumnCard>
      <ColumnCard icon={NetWorth}>
        <span className={styles.emphasis}>85,000+</span> small-business loans
        issued
      </ColumnCard>
      <ColumnCard icon={Savings}>
        Financing for small businesses across
        <br />
        <span className={styles.emphasis}>all 50 states</span>
      </ColumnCard>
      <ColumnCard icon={Banking}>
        <span className={styles.emphasis}>25+ lenders</span>
        <br />
        in our network
      </ColumnCard>
    </Columns>
  )
}

export default SocialProof
