import React from "react"
import PropTypes from "prop-types"

import styles from "./Box.module.less"

const Box = ({ children }) => {
  return <div className={styles.whiteBlock}>{children}</div>
}

Box.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Box
