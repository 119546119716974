import React from "react"
import PropTypes from "prop-types"
import classNames from "classnames"

import styles from "./ColumnCard.module.less"

import PersonalLoans from "@nerdwallet/react-icon/nerdwallet-brand/PersonalLoans"
import NetWorth from "@nerdwallet/react-icon/nerdwallet-brand/NetWorth"
import Savings from "@nerdwallet/react-icon/nerdwallet-brand/Savings"
import Banking from "@nerdwallet/react-icon/nerdwallet-brand/Banking"

import Column from "@src/components/Columns/Column"

const themes = {
  [PersonalLoans]: { bg: "#DFEFFF", color: "#005FB9", wide: false },
  [NetWorth]: { bg: "#E3F9F1", color: "#40AD87", wide: true },
  [Savings]: { bg: "#FEEFD0", color: "#EFA92E", wide: true },
  [Banking]: { bg: "#E9E2E9", color: "#9F6A93", wide: false },
}

const WIDE_WIDTH = {
  mobile: 12,
  desktop: 7,
}

const NARROW_WIDTH = {
  mobile: 12,
  desktop: 5,
}

const ColumnCard = ({ icon, children }) => {
  const Icon = icon

  const { bg, color, wide } = themes[Icon]
  const style = {
    "--bg": bg,
    "--color": color,
  }

  const width = wide ? WIDE_WIDTH : NARROW_WIDTH

  return (
    <Column width={width}>
      <div
        style={style}
        className={classNames(styles.card, { [styles.wide]: wide })}
      >
        <Icon className={styles.icon} />
        <div className={styles.text}>{children}</div>
      </div>
    </Column>
  )
}

ColumnCard.propTypes = {
  icon: PropTypes.oneOf(Object.keys(themes)).isRequired,
  children: PropTypes.node.isRequired,
}

export default ColumnCard
