import React from "react"
import PropTypes from "prop-types"
import { Helmet } from "react-helmet"

import { useAnalyticsPageTrack } from "@src/hooks/useAnalyticsPageTrack"
import { PageImpression } from "@src/lib/impression"

import { Text } from "@nerdwallet/react-typography"

import Page, { Footer, Header, HeaderContent } from "@src/components/Page"
import Section from "@src/components/SmallBusinessLoansLandingPage/Section"
import SectionTitle from "@src/components/SmallBusinessLoansLandingPage/Title"
import { CenteredContainer } from "@nerdwallet/react-layout"

import Hero from "@src/components/SmallBusinessLoansHero"
import LoanProcess from "@src/components/LoanProcess"
import NoOp from "@src/components/NoOp"
import SocialProof from "@src/components/SocialProof"
import FaqSection from "@src/components/FaqSection"
import faqData from "./faq-loans-landing-page-data"
import { LocationVariantProvider } from "@src/hooks/useLocationVariant"
import { ApplyUrlProvider } from "@src/hooks/useApplyUrl"
import TypesOfSmallBusinessSection from "./types-of-small-business-section"
import ApplyCTA from "@src/components/ApplyCTA"
import WPPostList from "@src/components/StructuredContentRenderer/WPPostList"
import nextStepsData from "./next-steps-data"
import faqsSchemaData from "./faq-loans-schema-data.json"

import { DEFAULT_SEO_IMAGE } from "@src/lib/constants"

import Band from "@src/components/Band"
import Box from "@src/components/SmallBusinessLoansLandingPage/Box"
import List from "@src/components/SmallBusinessLoansLandingPage/List"
import Column from "@src/components/Columns/Column"
import Columns from "@src/components/Columns/Columns"

const PUBLISH_DATE = "2022-06-29T17:26:12.000Z"
const ATTRIBUTION_TOPIC = "Small Business Loans"

const SmallBusinessLoansPage = ({ location }) => {
  useAnalyticsPageTrack()
  const title = "Small-Business Loans"
  const breadcrumb = {
    page: {
      title,
    },
    slug: "SMALL_BUSINESS",
  }

  const seo = {
    title: "Small-Business Loans: A Business Owner's Guide for 2022",
    description:
      "Getting a small-business loan can be tough, but we can help. With one application, you can compare options and find the right loan for your business.",
  }

  const jsonld = {
    "@type": "Article",
    about: [
      {
        "@type": "Thing",
        name: "Small Business",
      },
      {
        "@type": "Thing",
        name: "Small Business Loans",
      },
    ],
    author: {
      "@type": "Person",
      image:
        "https://assets.nerdwallet.com/blog/wp-content/uploads/2020/12/Randa-Kriss-headshot-160x160.png",
      name: "Randa Kriss",
      award: null,
      honorificSuffix: null,
      jobTitle: "Writer",
      url: "https://www.nerdwallet.com/blog/author/rkriss/",
      description:
        'Randa Kriss is a small-business writer who joined NerdWallet in 2020. She previously worked as a writer at Fundera, covering a wide variety of small-business topics including banking and loan products. Her work has been featured by The Washington Post, The Associated Press and Nasdaq, among others. Randa earned a bachelor\'s degree in English and Spanish at Iona College. Email: <a href="mailto:rkriss@nerdwallet.com">rkriss@nerdwallet.com</a>.',
      alumniOf: {
        "@type": "EducationalOrganization",
        name: "Iona College",
      },
    },
    dateModified: PUBLISH_DATE,
    datePublished: PUBLISH_DATE,
    headline: title,
    image: {
      "@type": "ImageObject",
      url: DEFAULT_SEO_IMAGE,
    },
    mainEntityOfPage: {
      "@type": "WebPage",
      "@id": "https://www.nerdwallet.com/small-business-loans-legacy-hub",
      reviewedBy: {},
      url: "https://www.nerdwallet.com/small-business-loans-legacy-hub",
    },
    publisher: {
      "@id": "https://www.nerdwallet.com/#organization",
    },
  }

  const authors = [
    {
      id: "1048",
      link: "https://www.nerdwallet.com/blog/author/rkriss/",
      name: "Randa Kriss",
    },
  ]

  const header = <Header attributionTopic={ATTRIBUTION_TOPIC} hideBanner />

  const headerContent = (
    <HeaderContent
      title={title}
      date={PUBLISH_DATE}
      authors={authors}
      hideAuthorByline
      hideFeaturedImage
      breadcrumb={breadcrumb}
      heroBannerDocument={<Hero title={title} />}
      hideEditorialStandards
    />
  )
  const footer = <Footer attributionTopic={ATTRIBUTION_TOPIC} hideBanner />

  return (
    <PageImpression>
      <LocationVariantProvider location={location} assignments={{}}>
        <ApplyUrlProvider
          location={location}
          attributionTopic={ATTRIBUTION_TOPIC}
        >
          <Page
            title={title}
            link="https://www.nerdwallet.com/small-business-loans-legacy-hub"
            seo={seo}
            jsonld={jsonld}
            header={header}
            headerContent={headerContent}
            footer={footer}
            contentWrapper={NoOp}
          >
            <Helmet>
              <meta name="robots" content="noindex" />
            </Helmet>
            <CenteredContainer>
              <Section>
                <SectionTitle align="center">
                  How Fundera by NerdWallet works
                </SectionTitle>
                <LoanProcess />
              </Section>
              <Section>
                <SectionTitle component="div" align="center">
                  Small business - big impact
                </SectionTitle>
                <SocialProof />
              </Section>
            </CenteredContainer>
            <Band>
              <CenteredContainer>
                <Section>
                  <SectionTitle component="h2">
                    A beginner’s guide to small-business loans
                  </SectionTitle>
                  <Columns gap>
                    <Column width={{ mobile: 12, desktop: 6 }}>
                      <Box>
                        <SectionTitle>
                          What is a small-business loan?
                        </SectionTitle>
                        <Text>
                          A small-business loan allows you to borrow money from
                          a lender to finance your operations. You can use a
                          business loan for a wide variety of purposes,
                          including but not limited to:
                          <List
                            items={[
                              "Working capital.",
                              "Renovations or expansions.",
                              "Buying inventory or equipment.",
                              "Hiring staff.",
                              "Business acquisitions.",
                              "Real estate purchases.",
                            ]}
                          />
                          Small-business loans are available from traditional
                          lenders, like banks and credit unions, as well as
                          online lenders, fintech companies and community
                          lending institutions.
                        </Text>
                      </Box>
                    </Column>
                    <Column width={{ mobile: 12, desktop: 6 }}>
                      <Box>
                        <SectionTitle>
                          How do small-business loans work?
                        </SectionTitle>
                        <Text>
                          With most small-business loans, you borrow capital
                          from a lender and repay it, plus interest, over a
                          predetermined period. Maximum loan amounts, interest
                          rates and repayment terms will vary based on the type
                          of business loan, the lender you’re working with and
                          your business’s qualifications.
                          <br />
                          <br />
                          When{" "}
                          <a href="/article/small-business/how-to-apply-small-business-loan">
                            applying for a small-business loan
                          </a>
                          , the lender will evaluate your business based on
                          underwriting criteria to determine if you qualify.
                          Every lender has different requirements, but they’ll
                          typically consider criteria such as your personal
                          credit score, annual revenue and time in business.
                          <br />
                          <br />
                          In general, having strong business credentials can
                          help you{" "}
                          <a href="/article/small-business/how-to-qualify-for-small-business-loans">
                            qualify for a small-business loan
                          </a>{" "}
                          with the most competitive interest rates and repayment
                          terms.
                        </Text>
                      </Box>
                    </Column>
                  </Columns>
                </Section>
                <Section>
                  <SectionTitle component="h2">
                    Types of small-business loans
                  </SectionTitle>
                  <TypesOfSmallBusinessSection />
                </Section>
                <Section>
                  <Box>
                    <SectionTitle>
                      What is the right small-business loan for me?
                    </SectionTitle>
                    <Text>
                      The right loan for your business will depend on a
                      combination of factors. You’ll want to consider why you
                      need funding, how much capital you need and the total cost
                      of any potential debt. You’ll also want to think about how
                      fast you need financing and what type of loan you can
                      qualify for.
                      <br />
                      <br />
                      When applying for a small-business loan with Fundera by
                      NerdWallet, you can see all of the loan options you
                      qualify for and compare them through a single platform for
                      free. As a marketplace, we connect you with a network of
                      vetted business lenders and offer tailored knowledge and
                      expertise to ensure you have everything you need through
                      each step of the funding process.
                    </Text>
                  </Box>
                </Section>
              </CenteredContainer>
            </Band>
            <CenteredContainer>
              <ApplyCTA />
              <Section>
                <SectionTitle>Frequently asked questions</SectionTitle>
                <FaqSection
                  {...faqData}
                  extraSchemaFaqs={faqsSchemaData.schema}
                />
              </Section>
              <Section>
                <SectionTitle component="div">Related links</SectionTitle>
                <WPPostList {...nextStepsData} />
              </Section>
            </CenteredContainer>
          </Page>
        </ApplyUrlProvider>
      </LocationVariantProvider>
    </PageImpression>
  )
}

SmallBusinessLoansPage.propTypes = {
  location: PropTypes.shape({
    pathname: PropTypes.string.isRequired,
  }).isRequired,
}

export default SmallBusinessLoansPage
